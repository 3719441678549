.fade-enter {
  opacity: 0.01;
  transform: translateY(100px);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in, transform 300ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transform: translateY(-100px);
  transition: opacity 300ms ease-in, transform 300ms ease-in-out;
}
