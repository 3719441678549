.progress-bar {
  position: relative;
  height: 10px;
  width: 100%;
  margin: auto 0 50px;
  background-color: var(--alpha-primary-700);
  border-radius: 20px;

  &__inner {
    position: absolute;
    height: 100%;
    border-radius: 20px;
    background-color: var(--color-secondary-500);
    transition: width 0.3s ease-in-out;
  }

  &__value {
    position: absolute;
    top: 22px;
    right: 0;
    display: block;
    padding: 2px 14px;
    background-color: var(--color-primary-600);
    border-radius: 8px;
    border: 2px solid var(--color-secondary-500);
    font-size: 1.8rem;
    color: var(--color-secondary-500);
    white-space: nowrap;

    &--left {
      left: 0;
      right: initial;
    }
  }
}
