@font-face {
  font-family: "Cera Pro Regular";
  src: url("./assets/fonts/Cera-Pro-Regular.otf");
}

@font-face {
  font-family: "Cera Pro Medium";
  src: url("./assets/fonts/Cera-Pro-Medium.otf");
}

@font-face {
  font-family: "Cera Pro Bold";
  src: url("./assets/fonts/Cera-Pro-Bold.otf");
}

@font-face {
  font-family: "IBM Plex Medium";
  src: url("./assets/fonts/IBMPlexMono-Medium.ttf");
}

@font-face {
  font-family: "IBM Plex SemidBold";
  src: url("./assets/fonts/IBMPlexMono-SemiBold.ttf");
}

:root {
  /* COLORS */
  --color-primary-100: #c1b6f0;
  --color-primary-300: #7057d8;
  --color-primary-500: #3e26a3;
  --color-primary-600: #281188;
  --color-primary-700: #1f1350;
  --color-secondary-300: #6cf6ce;
  --color-secondary-500: #0eeead;
  --color-secondary-700: #088e67;
  --color-accent-500: #7b5ff0;
  --color-accent-600: #5c41cd;
  --color-success-300: #c4eac2;
  --color-success-500: #6cca67;
  --color-success-700: #41793e;
  --color-error-300: #eeafb8;
  --color-error-500: #d4364d;
  --color-error-600: #ff1d4c;
  --color-error-700: #7f202e;
  --color-warning-300: #f6c3aa;
  --color-warning-500: #ff822b;
  --color-warning-700: #8b3e1a;
  --color-white: #fff;
  --color-black: #232326;
  --color-grey-100: #fff;
  --color-grey-300: #efeef1;
  --color-grey-500: #bab6c3;
  --color-grey-600: #59575e;
  --color-grey-700: #4e4d53;

  /* Cookies */
  --color-tertiary: #7e51e3;
  --color-primary-light: #5c41cd;
  --color-secondary: #0eeead;

  /* Alpha colors */
  --alpha-primary-300: rgba(92, 65, 205, 0.3);
  --alpha-primary-700: rgba(92, 65, 205, 0.7);
  --alpha-black-500: rgba(0, 0, 0, 0.5);

  /* Shadow */
  --shadow: rgba(17, 8, 54, 0.15);

  /* Primary font */
  --font-primary-regular: "Cera Pro Regular", sans-serif;
  --font-primary-medium: "Cera Pro Medium", sans-serif;
  --font-primary-bold: "Cera Pro Bold", sans-serif;

  /* Secondary font */
  --font-secondary-medium: "IBM Plex Medium", mono;
  --font-secondary-semibold: "IBM Plex SemiBold", mono;
}
