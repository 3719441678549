.cookies-consent {
  position: fixed;
  z-index: 998;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: var(--color-tertiary);
  padding: 30px;
  color: #fff;
  font-family: var(--font-primary-regular);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.cookies-consent.hidden {
  opacity: 0;
  visibility: hidden;
}

.cookies-consent__content {
  margin: 0 70px 0 0;
  line-height: 1.4;
  font-size: 16px;
}

.cookies-consent__content a {
  font-family: var(--font-primary-medium);
  color: #fff;
  font-weight: 600;
}

.cookies-consent__actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cookies-consent__actions__item {
  margin: 8px;
  display: block;
}

.cookies-consent__button {
  background-color: var(--color-secondary-500);
  padding: 8px 16px;
  border-radius: 10px;
  color: var(--color-tertiary);
  cursor: pointer;
  transition: opacity .3s ease-in-out;
}

.cookies-consent__button:hover {
  opacity: 0.9;
}

.cookies-consent__button span {
  display: block;
  font-size: 12px;
  line-height: 1;
  font-family: var(--font-primary-medium);
  text-align: center;
  min-width: 180px;
}

.cookies-consent__button span.action-highlight {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.cookies-consent__link {
  min-width: 190px;
  line-height: 1;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  transition: opacity .3s ease-in-out;
}

.cookies-consent__link:hover {
  opacity: 0.8;
}

.cookies-config-opener {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  text-align: center;
  width: 100%;

  .bg-wrong & {
    opacity: 0;
  }

  .bg-perfect & {
    opacity: 0;
  }

  button {
    background-color: var(--color-primary-600);
    padding: 10px 20px;
    border-radius: 30px;
    color: var(--color-primary-100);
  }
}

@media all and (max-width: 1200px) {
  .cookies-consent {
      flex-direction: column;
  }
  .cookies-consent__content {
      margin-bottom: 20px;
      margin-right: 0;
      font-size: 14px;
  }

  .cookies-consent__button {
      max-width: 300px;
  }
}

@media all and (max-width: 1024px) {
  .cookies-consent__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }

  .cookies-consent__content {
      text-align: center;
  }
}

/* Config popup */

.cookies-consent-params {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.cookies-consent-params__popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  max-width: 1000px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  padding: 30px;
  background-color: var(--color-white);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 15px;

  p {
    margin: 0;
  }
}

.cookies-consent-params .cookies-title {
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--font-primary-bold);
  color: var(--color-grey-600);
  font-size: 28px;
  line-height: 1;
}

.cookies-consent-params .cookies__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.cookies-desc {
  width: calc(100% - 150px);
  font-family: var(--font-primary-regular);
  line-height: 1.3;
  font-size: 14px;
  font-weight: 100;
  color: var(--color-grey-700);
}

.cookies-list__item {
  margin-bottom: 20px;
}

.cookies-list__item__label {
  color: var(--color-grey-700);
  font-family: var(--font-secondary-medium);
  font-size: 18px;
}

.cookies-validate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.cookies-validate__btn {
  background-color: var(--color-primary-light);
  color: var(--color-white);
  padding: 10px 25px;
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 18px;
  font-family: var(--font-primary-medium);
  cursor: pointer;
}

@media all and (max-width: 1024px) {
  .cookies-consent-params__popup {
      width: 100%;
      max-width: initial;
      max-height: initial;
      height: 100%;
      overflow-y: auto;
      left: 0;
      top: 0;
      transform: none;
      border-radius: 0;
      padding: 30px 20px 80px;
  }

  .cookies-title {
      margin: 20px;
  }

  .cookies-desc {
      width: 100%;
  }

  .cookies-list__item__label {
      font-size: 16px;
      padding-right: 10px;
  }

  .cookies-validate {
      position: fixed;
      bottom: 20px;
      left: 0;
      right: 0;
  }
}

/* Switch */

.cookies__switch {
  position: relative;
  display: inline-block;
  width: 55px;
  min-width: 55px;
  height: 31px;
}

.cookies__switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.cookies__switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: all .2s ease-in-out;
  border-radius: 34px;
}

.cookies__switch__slider::before {
  position: absolute;
  content: '';
  height: 23px;
  width: 23px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: all .2s ease-in-out;
}

.cookies__switch input:checked + .cookies__switch__slider {
  background-color: var(--color-secondary);
}

.cookies__switch input:focus + .cookies__switch__slider {
  box-shadow: 0 0 1px var(--color-secondary);
}


.cookies__switch input:checked + .cookies__switch__slider:before {
  transform: translateX(24px);
}
