.results {
  position: relative;

  &__content {
    transition: opacity 0.4s ease-in;
    opacity: 0;

    &.hide-results {
      display: none;
    }
  }

  h1 {
    text-align: center;

    @include mobile {
      margin: 20px 0 40px;
    }
  }

  &__score {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 214px;
    width: 214px;
    margin: 0 auto 40px;
    background-color: var(--color-secondary-500);
    border-radius: 50%;

    span {
      color: var(--color-primary-600);
      font-size: 1.8rem;
      line-height: 23px;
      font-family: var(--font-secondary-medium);
      margin-bottom: 10px;

      &:first-child {
        font-size: 8rem;
        line-height: 80px;
      }
    }
  }

  &__text {
    text-align: center;
    color: var(--color-white);
    font-size: 1.8rem;
    line-height: 32px;

    a {
      color: var(--color-secondary-500);
    }
  }

  &__details {
    margin: 80px 0;

    &__title {
      color: var(--color-accent-500);
      margin-bottom: 30px;

      @include mobile {
        text-align: center;
      }
    }
  }

  .statistics {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      border-bottom: 2px solid var(--alpha-primary-300);

      @include mobile {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px 0;
      }
    }

    &__label {
      margin: 0;
      color: var(--color-white);

      @include mobile {
        margin-bottom: 20px;
      }
    }

    &__value {
      display: flex;
      align-items: center;

      @include mobile {
        width: 100%;
      }
    }

    &__percent {
      display: block;
      margin-right: 20px;
      color: var(--color-accent-500);
    }

    &__bar {
      display: block;
      background-color: var(--color-primary-700);
      height: 30px;
      width: 100px;

      @include mobile {
        flex: 1 1 auto;
        max-width: 230px;
        margin-left: auto;
      }

      &__value {
        display: block;
        height: 100%;

        &--red {
          background-color: var(--color-error-600);
        }

        &--orange {
          background-color: var(--color-warning-500);
        }

        &--green {
          background-color: var(--color-secondary-500);
        }
      }
    }
  }

  .contact-us {
    &__title {
      font-size: 3.6rem;
      line-height: 46px;
      text-align: center;
      margin: 60px 0 30px;

      @include mobile {
        font-size: 2.5rem;
        line-height: 35px;
        margin: 60px 0 40px;
      }
    }

    &__avatar {
      position: relative;
      width: 200px;
      height: 200px;
      min-width: 200px;
      min-height: 200px;
      border-radius: 50%;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__block {
      display: flex;
      align-items: center;

      @include mobile-s {
        flex-direction: column;
      }
    }

    &__choices {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      margin-left: 50px;
      flex: 1 1 auto;

      @include mobile {
        flex-direction: column;
      }

      @include mobile-s {
        margin: 40px 0 0;
      }

      &__item {
        width: calc(100% / 3 - 10px);

        @include mobile {
          width: 100%;
          margin: 5px 0;
        }

        button {
          width: 100%;
          height: 100%;
          padding: 20px;
          background-color: var(--color-white);
          color: var(--color-primary-600);
          border-radius: 10px;
          line-height: 20px;
          transition: background-color 0.3s ease-in-out;

          &:hover {
            background-color: var(--color-secondary-500);
          }
        }
      }
    }

    &__after-choice p {
      font-size: 2.5rem;
      line-height: 35px;
      margin: 0;

      @include mobile {
        text-align: center;
      }
    }
  }

  .data-form {
    transition: opacity 0.3s ease-in-out;

    &.hide-form {
      display: none;
      opacity: 0;
    }

    &__block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }

      label {
        margin: 10px;

        @include mobile {
          margin: 10px 0;
          width: 100%;
        }

        input {
          @include mobile {
            width: 100%;
          }
        }

        span {
          display: block;
          margin: 0 0 5px 10px;
          color: var(--color-primary-100);
          font-size: 1.2rem;
        }
      }
    }

    &__submit {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px 0;

      @include mobile {
        margin: 30px 0;
      }

      .button {
        min-width: 350px;

        @include mobile {
          min-width: initial;
          width: 100%;
        }
      }
    }

    &__consent {
      margin: 20px 0;

      .form-checkbox {
        align-items: flex-start;
        border: none;
        margin-right: 18px;

        &__label {
          font-family: var(--font-primary-regular);
          max-width: 600px;
          line-height: 24px;
        }

        input {
          &:checked {
            & ~ .form-checkbox__label {
              color: var(--color-white);
            }
          }
        }
      }
    }
  }
}
