.new-chapter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: calc(100vh - var(--header-height));

  @include mobile {
    min-height: calc(100vh - var(--mobile-header-height));
  }

  &__num {
    font-family: var(--font-primary-regular);
    font-size: 3.6rem;
    line-height: 45px;

    @include tablet {
      font-size: 3rem;
      line-height: 50px;
    }
  }

  &__title {
    color: var(--color-secondary-500);
    font-size: 6rem;
    font-weight: 100;
    line-height: 80px;
    font-family: var(--font-secondary-medium);
    text-transform: uppercase;
    margin-bottom: 40px;

    @include tablet {
      font-size: 3rem;
      line-height: 50px;
    }
  }

  .button {
    margin-top: auto;
    align-self: flex-end;

    @include mobile {
      width: 100%;
    }
  }
}
