.app-modal {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--alpha-black-500);
  transform: translateY(-20px);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  &__body {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - 40px));
    width: calc(100% - 40px);
    max-width: 820px;
    max-height: 100%;
    min-height: 50vh;
    overflow-y: auto;
    margin-top: 40px;
    padding: 60px;
    background-color: var(--color-white);
    border-radius: 10px;

    @include tablet {
      width: 100%;
      max-width: initial;
      height: 100%;
      margin: 0;
      border-radius: 0;
      left: 0;
      top: 0;
      transform: none;
      padding: 80px 30px;
    }
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    color: var(--color-primary-600);
  }
}
