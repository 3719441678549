.app-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  width: 100%;
  padding: 20px 40px;

  @include mobile {
    height: var(--mobile-header-height);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 200px 200px 0;
    border-color: transparent var(--color-accent-600) transparent transparent;

    @include tablet {
      display: none;
    }

    @include desktop-xl {
      border-width: 600px 0 0 600px;
    }
  }

  &__logo {
    width: 116px;
  }

  .go-to-website {
    position: relative;
    color: var(--color-white);
    padding: 20px 10px 10px;
  }
}
