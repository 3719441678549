/* stylelint-disable no-descending-specificity */
.questionnaire {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 900px;
}

.chapter-name,
.form-name {
  font-family: var(--font-secondary-medium);
  font-size: 1.8rem;
  line-height: 30px;
  color: var(--color-primary-300);
  transform: translateX(-30px);
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out;

  @include tablet {
    transform: none;
  }
}

.form-name {
  font-size: 2rem;
  line-height: 1.6;
  margin-bottom: 50px;
}

.question {
  font-size: 3.4rem;
  line-height: 50px;
  color: var(--color-white);

  @include tablet {
    font-size: 2.5rem;
    line-height: 40px;
  }

  @include mobile {
    font-size: 2rem;
    line-height: 30px;
  }

  a {
    background-color: var(--color-white);
    color: var(--color-primary-600);
    padding: 8px 10px 5px;
    font-size: 3rem;

    @include tablet {
      font-size: 2.5rem;
    }

    @include mobile {
      font-size: 2rem;
      padding: 5px 10px 3px;
    }

    &:hover {
      filter: brightness(95%);
    }

    .bg-wrong & {
      color: var(--color-error-600);
    }

    .bg-perfect & {
      color: var(--color-secondary-500);
    }
  }

  span {
    color: var(--color-secondary-500);
    cursor: help;

    &::after {
      content: 'ⓘ';
      font-size: 2rem;
      display: inline-block;
      margin: 0 5px 0 10px;
      transform: translateY(-10px);

      @include mobile {
        font-size: 1.3rem;
        margin: 0 3px 0 5px;
        transform: translateY(-7px);
      }
    }

    a {
      background-color: transparent;
      color: var(--color-secondary-500);
      padding: 0;
      font-size: 3.4rem;
    }
  }

  &.form-perfect {
    color: var(--color-primary-600);
  }
}

.question-wrapper {
  margin-bottom: 50px;
  opacity: 1;
  transform: translateX(0);
  width: 100%;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &.exit {
    opacity: 0;
    transform: translateX(-40px);
  }

  &.transit {
    transform: translateX(40px);
  }

  &.enter {
    opacity: 1;
    transform: translateX(0);
  }
}

.answers {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 50px 0 0;

  @include tablet {
    flex-direction: column;
  }

  &__item {
    margin: 23px;
    flex: 1;

    @include tablet {
      margin: 10px 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &.selected {
      .button {
        background-color: var(--color-secondary-500);
        border-color: var(--color-secondary-500);
      }
    }
  }

  &--more {
    flex-wrap: wrap;

    .answers__item {
      flex: initial;
      margin: 10px;
      width: calc(100% / 3 - 20px);

      @include tablet {
        width: 100%;
      }
    }
  }

  &--form {
    flex-direction: column;

    .answers__item {
      width: 100%;
      margin: 5px 0;
    }
  }

  &--form-choice {
    flex-direction: column;

    .answers__item {
      width: 100%;
      margin: 5px 0;
    }
  }
}

.next-question {
  margin: 30px 0 0 auto;

  @include mobile {
    width: 100%;
  }
}

body {
  &.bg-perfect,
  &.bg-wrong {
    .chapter-name,
    .form-name {
      opacity: 0;
      height: 0;
      margin: 0;
    }
  }

  &.bg-perfect {
    background-color: var(--color-secondary-500);

    .button {
      background-color: transparent;
      border-color: var(--color-primary-600);
      color: var(--color-primary-600);

      &:hover {
        color: var(--color-primary-600);
      }
    }
  }

  &.bg-wrong {
    background-color: var(--color-error-600);

    .button {
      background-color: transparent;
      border-color: var(--color-white);

      &:hover {
        color: var(--color-white);
      }
    }
  }
}

.skip-forms {
  display: block;
  width: 100%;
  margin: 50px 0 0 auto;
  text-align: right;
}
