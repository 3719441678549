@mixin outline-none {
  outline: none;

  &:active,
  &:focus,
  &:visited {
    outline: none;
  }
}

// RESPONSIVE
$mobile-xs: 374px;
$mobile-s: 768px;
$mobile: 1023px;
$tablet: 1200px;
$laptop: 1439px;
$desktop-xl: 1600px;

@mixin mobile-xs {
  @media all and (max-width: $mobile-xs) { @content; }
}

@mixin mobile-s {
  @media all and (max-width: $mobile-s) { @content; }
}

@mixin mobile {
  @media all and (max-width: $mobile) { @content; }
}

@mixin tablet {
  @media all and (max-width: $tablet) { @content; }
}

@mixin laptop {
  @media all and (max-width: $laptop) { @content; }
}

@mixin desktop-xl {
  @media all and (min-width: $desktop-xl) { @content; }
}
