.introduction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  p {
    color: var(--color-white);
  }

  .secondary {
    color: var(--color-secondary-500);
  }

  @include mobile {
    padding-bottom: 50vh;
  }

  .num-list {
    width: 100%;
  }

  .btn-go {
    margin-left: auto;
    margin-top: 60px;

    @include mobile {
      width: 100%;
      margin-top: 20px;
    }
  }

  .website-input {
    margin-top: 40px;

    @include mobile {
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      line-height: 28px;
      margin: 0;
    }

    input {
      margin-top: 20px;
      width: 100%;
      max-width: 400px;

      &.error {
        border-color: var(--color-error-600);
      }
    }

    &__error {
      color: var(--color-error-600);
      display: block;
      font-size: 1.4rem;
      line-height: 1.6;
      margin: 20px 0;
    }
  }

  .mobile-fixed {
    width: 100%;

    @include mobile {
      position: fixed;
      left: 0;
      bottom: 0;
      padding: 31px;
      background-color: var(--color-primary-600);
      box-shadow: 0 0 30px var(--color-primary-700);
    }
  }
}
