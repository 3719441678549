.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 240px;
  min-height: 60px;
  padding: 10px 24px 12px 28px;
  background-color: var(--color-primary-600);
  border: 2px solid var(--color-secondary-500);
  border-radius: 30px;
  font-family: var(--font-primary-medium);
  font-size: 1.6rem;
  line-height: 20px;
  letter-spacing: 0.2px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, opacity 0.3s ease-in-out;

  svg {
    margin-left: 10px;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    background-color: var(--color-secondary-500);
    color: var(--color-primary-600);

    svg {
      transform: translateX(10px);
    }
  }

  &--answer {
    display: block;
    height: 100%;
    width: 100%;
    min-width: initial;
    padding: 20px 16px;
    background-color: var(--color-white);
    border: 4px solid var(--color-white);
    border-radius: 10px;
    color: var(--color-primary-600);
    text-align: center;
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      border-color: var(--color-secondary-500);
      background-color: var(--color-white);
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}
