input {
  width: 100%;
  border-radius: 30px;
  padding: 15px 30px;
  outline: none;
  border: 1px solid var(--color-white);
  background-color: transparent;
  color: var(--color-white);
  font-size: 1.6rem;
  font-family: var(--font-secondary-medium);
  transition: border-color 0.5s ease-in-out;

  &:focus {
    border-color: var(--color-secondary-500);
  }

  &::placeholder {
    color: var(--color-primary-300);
  }
}
