.max-wrapper {
  max-width: 924px;
  margin: 0 auto;
  padding: 0 40px 100px;
  min-height: calc(100vh - var(--header-height));
}

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: var(--header-height);

  @include mobile {
    margin-top: var(--mobile-header-height);
  }
}

h1 {
  font-size: 4.4rem;
  line-height: 60px;
  font-family: var(--font-primary-bold);
  margin-bottom: 30px;

  @include mobile {
    font-size: 3.2rem;
    line-height: 42px;
  }
}

p {
  color: var(--color-primary-100);
  font-size: 1.6rem;
  margin-bottom: 30px;
}

.num-list {
  & > * {
    font-family: var(--font-secondary-medium);
    font-size: 1.8rem;
    padding: 12px 0;
    border-bottom: 2px solid var(--alpha-primary-300);
  }
}

.green-triangle {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 300px 0 0 300px;
    border-color: transparent transparent transparent var(--color-secondary-500);

    @include tablet {
      display: none;
    }

    @include desktop-xl {
      border-width: 600px 0 0 600px;
    }
  }

  .max-wrapper {
    position: relative;
  }
}

.green-points-bg {
  position: relative;
  min-height: calc(100vh - var(--header-height));

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 60px;
    height: 300px;
    width: calc(100% - 120px);
    background-image: url('./bg/green-point.png');
    background-position: center;
  }
}
