.form-warning {
  .warning-question {
    font-size: 3rem;
    line-height: 40px;
    color: var(--color-primary-600);
    font-family: var(--font-primary-medium);
    text-align: center;

    @include tablet {
      font-size: 2.3rem;
      line-height: 30px;
      text-align: left;
    }
  }

  .answers {
    flex-direction: column;

    &__item {
      margin: 0;

      .button {
        border: 2px solid var(--color-primary-600);
        font-size: 1.8rem;
        margin: 5px 0;

        &:hover {
          border-color: var(--color-secondary-500);
        }
      }
    }
  }
}
