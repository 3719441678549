body .app-tooltip {
  max-width: 340px;
  min-width: 200px;
  padding: 15px 20px;
  background-color: var(--color-primary-700);
  border-radius: 5px;
  color: var(--color-white);
  font-size: 1.4rem;
  line-height: 20px;
  box-shadow: 0 0 10px var(--color-primary-700);
  box-shadow: 0 5px 30px 0 var(--shadow);

  &::before,
  &::after {
    content: none;
  }

  &.show {
    opacity: 1;
  }
}
