.form-checkbox {
  display: flex;
  align-items: center;
  border: 2px solid var(--color-white);
  padding: 15px;
  margin: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:hover {
    border-color: var(--color-secondary-500);
  }

  .checked & {
    background-color: var(--color-white);
  }

  .disabled & {
    cursor: default;

    &:hover {
      border-color: var(--color-white);
    }
  }

  &__check {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    max-width: 30px;
    max-height: 30px;
    min-width: 30px;
    min-height: 30px;
    color: var(--color-white);
    margin-right: 20px;
    border: 2px solid var(--color-white);
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    svg {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &__label {
    font-size: 1.6rem;
    line-height: 18px;
    font-family: var(--font-primary-medium);
    transition: color 0.3s ease-in-out;
  }

  input {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:checked {
      & ~ .form-checkbox__check {
        background-color: var(--color-secondary-500);
        border-color: var(--color-secondary-500);

        &--wrong {
          background-color: var(--color-error-600);
          border-color: var(--color-error-600);

          svg {
            max-width: 15px;
            fill: var(--color-white);
          }
        }

        &--warning {
          background-color: var(--color-warning-500);
          border-color: var(--color-warning-500);

          svg {
            max-width: 15px;
            fill: var(--color-white);
          }
        }

        svg {
          opacity: 1;
        }
      }

      & ~ .form-checkbox__label {
        color: var(--color-primary-600);
      }
    }
  }
}
